import { Badge, Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { LftCertMaker } from './LftCertMaker'
import { VerifyLFT } from './VerifyLFT'
import { styled } from '@mui/material/styles'
import { collection, query, where } from 'firebase/firestore'
import { firestore } from './firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { PreRegistered } from './PreRegistered'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 15
  },
}))

export function Clinic() { // eslint-disable-line react/display-name
  const location = useLocation()
  const tab = location.pathname.split('/')[2] || 'lft-cert-maker'

  const pendingVerificadtionQuery = query(
    collection(firestore, 'samples'),
    where('status', '==', 'pending-verification'))

  const [pendingVerification] = useCollection(pendingVerificadtionQuery)

  let pendingVerificationCount = pendingVerification && pendingVerification.docs && pendingVerification.docs.length
  let ReviewSamplesLink = Link
  if (pendingVerificationCount) {
    ReviewSamplesLink = React.forwardRef((props, ref) => {
      return <StyledBadge badgeContent={pendingVerificationCount} color="primary">
        <Link ref={ref} {...props}/>
      </StyledBadge>
    })
  }

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <Tabs value={tab} className="TopMenu" variant="scrollable" scrollButtons="auto">
          <Tab label="LFT Cert Maker" value="lft-cert-maker" to="lft-cert-maker" component={Link}/>
          <Tab label="Review Samples" value="review-samples" to="review-samples" component={ReviewSamplesLink}/>
          <Tab label="Pre-Registered" value="pre-registered" to="pre-registered" component={Link}/>
          <Tab label="Certificate of Recovery" value="cert-recovery" to="cert-recovery" component={Link}/>
          <Tab label="Barcode Generator" value="barcode-gen" to="barcode-gen" component={Link}/>
        </Tabs>
      </Box>
      <Routes>
        <Route path={'/'} element={<LftCertMaker/>}/>
        <Route path={'lft-cert-maker'} element={<LftCertMaker/>}/>
        <Route path={'review-samples'} element={<VerifyLFT/>}/>
        <Route path={'pre-registered'} element={<PreRegistered/>}/>
      </Routes>
    </Fragment>
  )
}
