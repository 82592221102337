import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  useFormControl
} from '@mui/material'
import { styled } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc } from 'firebase/firestore'
import { auth, firestore, useUserDetails } from './firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { Add, Delete } from '@mui/icons-material'
import { WhenReady } from './AccessDenied'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Title from './Title'
import Box from '@mui/material/Box'
import AsyncSelect from 'react-select/async'
import { useLocation } from 'react-router-dom'

export function ButtonWithConfirmation({ title, content, onConfirm, disabled, tooltip, ...props }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Tooltip title={tooltip}>
        <span>
        <Button onClick={handleClickOpen} disabled={disabled}>
          {props.children}
        </Button>
          </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            handleClose()
            onConfirm()
          }} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function IconButtonWithConfirmation({ title, content, onConfirm, disabled, tooltip, ...props }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Tooltip title={tooltip}>
        <span>
        <IconButton onClick={handleClickOpen} disabled={disabled}>
          {props.children}
        </IconButton>
          </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            handleClose()
            onConfirm()
          }} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function MenuItemWithConfirmation({ title, content, onConfirm, disabled, tooltip, ...props }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen} disabled={disabled}>
        <Box flexDirection={'row'} display={'flex'} gap={'1em'}>
          {props.children}
        </Box>
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            handleClose()
            onConfirm()
          }} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function ButtonWithQuestion({
                                     label,
                                     title = `New ${label}`,
                                     content,
                                     disabled,
                                     onConfirm,
                                     tooltip,
                                     type = 'text',
                                     defaultAnswer = '',
                                     ...props
                                   }) {
  const [open, setOpen] = useState(false)
  const [answer, setAnswer] = useState(defaultAnswer)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setAnswer('')
  }

  let button = <Button onClick={handleClickOpen} disabled={disabled}>
    {props.children}
  </Button>
  return (
    <div>
      {tooltip && <Tooltip title={tooltip}>{button}</Tooltip> || button}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
          <TextField autoFocus margin="dense" label={label} type={type} fullWidth variant="standard" value={answer}
                     onChange={e => setAnswer(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            handleClose()
            onConfirm(answer)
          }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const Pane = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
    margin: theme.spacing(3)
  }
}))

export function WhenNotEmpty({ data, message, ...props }) {
  return <Fragment>
    {data && ((data.docs && data.docs.length) || (Array.isArray(data) && data.length)) ? props.children : message}
  </Fragment>
}

export function RowIconMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const _open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function closeMenu() {
    setAnchorEl(null)
  }

  return <Fragment>
    <IconButton onClick={handleClick}><MoreVertIcon/></IconButton>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={_open}
      onClose={closeMenu}>
      {props.children}
    </Menu>
  </Fragment>
}

export function SimpleDataGridEditor({
                                       title,
                                       collectionPath,
                                       mainField,
                                       defaultSort,
                                       columns,
                                       idGenerator,
                                       addButtonBuilder
                                     }) {
  const apiRef = useGridApiRef()

  global.apiRef = apiRef

  const [sortModel, setSortModel] = useState([{ field: defaultSort || mainField }])

  const dataCollection = collection(firestore, collectionPath)
  const [data, loading, error] = useCollection(dataCollection)
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (data && data.docs && data.docs.length) {
      setRows(data.docs.map(a => ({ id: a.id, ...a.data() })))
    }
  }, [data])

  const handleDelete = async (id) => {
    await deleteDoc(doc(dataCollection, id))
  }

  function handleSave(a) {
    let { id, ...newData } = apiRef.current.getRow(a.id)

    if (a.row[mainField]) {
      const rowRef = data.docs.find(a => a.id === id)
      const db = rowRef.data()
      const shouldSave = Object.entries(newData).filter(([k, v]) => v !== db[k]).length > 0
      if (shouldSave) {
        setDoc(doc(dataCollection, id), newData, { merge: true })
      }
    } else {
      deleteDoc(doc(dataCollection, id))
    }
  }

  const [selectedRow, setSelectedRow] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  function handleDeleteDialog(params) {
    setSelectedRow(params)
    setOpenDeleteDialog(true)
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false)
    setSelectedRow(null)
  }

  const actionsColumn = columns.find(a => a.type === 'actions')
  columns = columns.filter(a => a.type !== 'actions')
  const allColumns = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      width: actionsColumn && actionsColumn.width || 60,
      getActions: (params) => {
        return [
          ...(actionsColumn ? actionsColumn.getActions(params) : []),
          <GridActionsCellItem
            showInMenu
            icon={<Delete/>}
            label="Remove record"
            onClick={() => handleDeleteDialog(params)}
          />,

          <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Delete record?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove this <b>{selectedRow && selectedRow.row[mainField]}</b> from the system?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
              <Button onClick={() => {
                handleCloseDeleteDialog()
                handleDelete(selectedRow.id)
              }} autoFocus>Confirm</Button>
            </DialogActions>
          </Dialog>
        ]
      }
    }
  ]

  function getColumnToFocus() {
    const main = columns.find(a => a.field === mainField)
    if (main.editable) {
      return mainField
    }
    return columns.find(a => a.editable).field
  }

  const [newId, setNewId] = useState(null)
  useEffect(() => {
    if (!newId || !data.docs.map(a => a.id === newId && a.data()).filter(a => a).length) {
      return
    }
    const id = newId
    setNewId(null)
    setTimeout(() => {
      apiRef.current.setRowMode(id, 'edit')
      setTimeout(() => apiRef.current.setCellFocus(id, getColumnToFocus()))
    })

  }, [data])

  function EditToolbar() {
    async function handleAdd(_id) {
      const id = _id || (idGenerator ? await idGenerator(dataCollection) : doc(dataCollection).id)
      setNewId(id)
      await setDoc(doc(dataCollection, id), {
        created: {
          by: auth.currentUser.email,
          timestamp: serverTimestamp()
        }
      })
    }

    return <GridToolbarContainer>
      {addButtonBuilder ? addButtonBuilder(handleAdd) : <Button color="primary" startIcon={<Add/>}
                                                                onClick={() => handleAdd()}>Add record</Button>}
    </GridToolbarContainer>
  }

  return (
    <WhenReady loading={loading} error={error}>
      <WhenNotEmpty data={data} message="empty dataset">
        {data &&
          <DataGridPro apiRef={apiRef}
                       style={{ border: 'none' }}
                       sortModel={sortModel}
                       disableColumnMenu
                       onSortModelChange={model => setSortModel(model)}
                       autoHeight={true}
                       rows={rows}
                       columns={allColumns}
                       hideFooter={true}
                       editMode={'row'}
                       onRowEditStop={handleSave}
                       components={{ Toolbar: EditToolbar }}

          />}
      </WhenNotEmpty>
    </WhenReady>
  )
}

export function AccordionGroup({ label, style, expanded, onChange, ...props }) {
  return <Accordion expanded={expanded} onChange={onChange}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}>
      <Title>{label}</Title>
    </AccordionSummary>
    <AccordionDetails>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1em', ...style }} {...props}>
        {props.children}
      </div>
    </AccordionDetails>
  </Accordion>
}

export function SelectFirestoreCollection({ value, multi, onChange, minWidth, dataPath, dataTransform }) {
  const { onBlur, onFocus, onEmpty, onFilled } = useFormControl() || {}
  const [options, setOptions] = useState(null)
  const [localValue, setLocalValue] = useState(value)

  const styles = {
    control: styles => ({ ...styles, minWidth, marginTop: '10px', })
  }
  return <AsyncSelect isMulti={multi} isClearable={true} closeMenuOnSelect={!multi} cacheOptions
                      defaultOptions
                      onBlur={onBlur}
                      onFocus={onFocus}
                      placeholder={''} value={localValue}
                      onChange={onChangeLocal}
                      loadOptions={loadOptions}
                      styles={styles}/>

  async function loadOptions(input) {
    try {
      let data = options
      if (!data) {
        const querySnapshot = await getDocs(collection(firestore, ...dataPath))
        data = querySnapshot.docs.map(dataTransform)
        if (typeof value === 'string') {
          let find = data.find(a => a.value === value)
          setLocalValue(find)
          updateLabel(find)
        }
        setOptions(data)
      }
      return data.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()))
    } catch (e) {
      console.error(e)
    }
  }

  function updateLabel(e) {
    if (Array.isArray(e) && e.length || e) {
      onFilled()
    } else {
      onEmpty()
    }
  }

  function onChangeLocal(e) {
    updateLabel(e)
    onChange(e)
  }
}

export function SelectStatic({ value, multi, onChange, minWidth, options, clearable = true }) {
  const { onBlur, onFocus, onEmpty, onFilled } = useFormControl() || {}
  const [localValue, setLocalValue] = useState(value)

  const styles = {
    control: styles => ({ ...styles, minWidth, marginTop: '10px', })
  }
  return <AsyncSelect isMulti={multi} isClearable={clearable} closeMenuOnSelect={!multi} cacheOptions
                      defaultOptions
                      onBlur={onBlur}
                      onFocus={onFocus}
                      placeholder={''} value={localValue}
                      onChange={onChangeLocal}
                      loadOptions={loadOptions}
                      styles={styles}/>

  async function loadOptions(input) {
    try {
      let data = options.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()))
      if (typeof value === 'string') {
        let find = data.find(a => a.value === value)
        setLocalValue(find)
        updateLabel(find)
      }
      return data
    } catch (e) {
      console.error(e)
    }
  }

  function updateLabel(e) {
    if (e) {
      onFilled()
    } else {
      onEmpty()
    }
  }

  function onChangeLocal(e) {
    setLocalValue(e)
    updateLabel(e)
    onChange(e)
  }
}

export function FormSelectStatic({ label, value, onChange, options }) {
  return <FormControl sx={{ minWidth: 200 }}>
    <InputLabel>{label}</InputLabel>
    <Select
      value={value}
      label={label}
      onChange={e => onChange(e.target.value)}>
      {Object.entries(options).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
    </Select>
  </FormControl>
}


export function FormRadioGroupStatic({ label, value, onChange, options }) {
  return <FormControl sx={{ minWidth: 200}}>
    <FormLabel>{label}</FormLabel>
    <RadioGroup value={value} onChange={e => onChange(e.target.value)}>
      {Object.entries(options).map(([key, value]) => <FormControlLabel key={key} value={key} control={<Radio />} label={value} />)}
    </RadioGroup>
  </FormControl>
}


export function debounce(cb, ms) {
  let timeout
  let lastArgs
  const f = (...args) => {
    lastArgs = args
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      timeout = undefined
      cb(...lastArgs)
      lastArgs = undefined
    }, ms)
  }

  f.callNow = () => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
    cb(...lastArgs)
    lastArgs = undefined
  }

  return f
}

export function DebouncedInput({ value, delay = 300, onChange, ...props }) {
  const [localValue, setLocalValue] = useState(value)
  onChange = debounce(onChange, delay)
  return <Input
    {...props}
    value={localValue}
    onChange={e => {
      setLocalValue(e.target.value)
      onChange(e)
    }}
  />
}

export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => {
    const query = new URLSearchParams(search)
    return { query, ...Object.fromEntries(query.entries()) }
  }, [search])
}

export function Privileged({ access, children }) {
  const [user] = useUserDetails()
  return user && user.can(access) ? children : null
}
