/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment } from 'react'
import { Pane } from './ReactUtils'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection, doc, getDoc, limit, orderBy, query, where, writeBatch } from 'firebase/firestore'
import { firestore } from './firebase'
import { WhenReady } from './AccessDenied'
import { DataGridPro } from '@mui/x-data-grid-pro'
import Title from './Title'
import { Box, Button } from '@mui/material'
import moment from 'moment'

const Grid = React.memo(({ samples, columns }) => {
  return <DataGridPro disableColumnMenu
                      autoHeight={true}
                      rows={samples.docs.map((a, index) => ({
                        ...a.data(),
                        id: a.id,
                        name: `${a.data().FORENAMES} ${a.data().SURNAME}`,
                        index
                      }))}
                      getRowId={row => row.index}
                      columns={columns}
                      getRowClassName={(params) => `row-status--${params.row.status}`}
                      hideFooter={true}/>
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.samples.docs) === JSON.stringify(nextProps.samples.docs)
})

export function LabRegistered() {
  let q = collection(firestore, 'samples')
  q = query(q, where('status', '==', 'registered'))
  q = query(q, limit(1000))
  q = query(q, orderBy('SAMPLE_DATE_VALUE', 'desc'))

  const [samples, loading, error] = useCollection(q)

  function isoToLocal(params) {
    return moment(params.row.SAMPLE_DATE_VALUE).format('DD/MM/YYYY HH:mm')
  }

  const columns = [
    { field: 'SAMPLE_DATE_VALUE', headerName: 'Appointment', flex: 1, valueGetter: isoToLocal },
    { field: 'providerId', headerName: 'Client', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'EMAIL', headerName: 'Email', flex: 1 },
    { field: 'TYPE_TEST', headerName: 'Test Type', flex: .5 }
  ]

  return <Fragment>
    <Pane>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>Pre-Registered</Title>
        <Button variant="contained" color="primary" onClick={async () => {
          const data = await getDoc(doc(firestore, 'settings', 'global'))
          const fields = data.data().labExportFields.split('\n').filter(a => a)
          const text = [...samples.docs.map(a => {
            let data = a.data()
            return fields.map(field => data[field] || '').join('\t')
          })].join('\n')
          await navigator.clipboard.writeText(text)
          if (window.confirm('Data copied to clipboard. Do you want to mark these samples as Registered?')) {
            const batch = writeBatch(firestore)
            samples.docs.forEach(a => batch.update(a.ref, { status: 'received' }))
            await batch.commit()
          }
        }}>Copy to Clipboard</Button>
      </Box>
      <WhenReady loading={loading} error={error} data={samples}>
        {samples && samples.docs && samples.docs.length && <Grid samples={samples} columns={columns}/> || <div></div>}
      </WhenReady>
    </Pane>
  </Fragment>
}
