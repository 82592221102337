/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useState } from 'react'
import { FormRadioGroupStatic, Pane } from './ReactUtils'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection, deleteDoc, doc, limit, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from './firebase'
import { WhenReady } from './AccessDenied'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'
import Title from './Title'
import { useNavigate } from 'react-router-dom'
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import { RESULT_TYPES, SAMPLE_METHODS } from './Workflows'
import moment from 'moment'
import { Delete } from '@mui/icons-material'
import { DateTimePicker } from '@mui/lab'
import { transformSample } from 'lims-server/src/util/Transforms'

const Grid = React.memo(({ samples, columns }) => {
  return <DataGridPro disableColumnMenu
                      autoHeight={true}
                      rows={samples.docs.map((a, index) => ({
                        ...a.data(),
                        id: a.id,
                        name: `${a.data().FORENAMES} ${a.data().SURNAME}`,
                        index
                      }))}
                      getRowId={row => row.index}
                      columns={columns}
                      getRowClassName={(params) => `row-status--${params.row.status}`}
                      hideFooter={true}/>
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.samples.docs) === JSON.stringify(nextProps.samples.docs)
})

export function PreRegistered() {
  const [status, setStatus] = useState('')
  const [processOpen, setProcessOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const navigate = useNavigate()
  let samplesCollection = collection(firestore, 'samples')
  let q = samplesCollection
  q = query(q, where('status', '==', 'available'))
  q = query(q, limit(100))
  q = query(q, orderBy('SAMPLE_DATE_VALUE', 'desc'))

  const [samples, loading, error] = useCollection(q)

  function handleDeleteDialog(params) {
    setSelectedRow(params)
    setOpenDeleteDialog(true)
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false)
    setSelectedRow(null)
  }

  async function handleDelete(id) {
    await deleteDoc(doc(samplesCollection, id))
  }

  function clearStatus() {
    setTimeout(() => {
      setStatus('')
    }, 1000)
  }

  function openProcess(row) {
    setSelectedRow(row)
    setTimeout(() => setProcessOpen(true))
  }

  function closeProcess() {
    setProcessOpen(false)
  }

  function edit(row) {
    navigate('/admin/samples/' + row.sampleId)
  }

  async function process({
                           result,
                           barcode,
                           sampleMethod,
                           action,
                           email,
                           sampleDate,
                           foreNames,
                           surname,
                           complete = true,
                           pcr
                         }) {
    const { sampleId } = selectedRow
    console.log('process:', sampleId)
    setStatus('saving data...')
    const sampleRef = doc(firestore, 'samples', sampleId)
    let data = {
      SAMPLE_METHOD: SAMPLE_METHODS[sampleMethod],
      SAMPLE_METHOD_VALUE: sampleMethod,
      BARCODE: barcode,
      BARCODE_PREFIX: /^(\D*)\d*/gm.exec(barcode)[1],
      EMAIL: email,
      SAMPLE_DATE_VALUE: sampleDate,
      FORENAMES: foreNames,
      SURNAME: surname
    }
    if (!pcr) {
      data = {
        ...data,
        RESULTS: RESULT_TYPES[result],
        RESULTS_value: result
      }
    }
    await transformSample(data)
    await updateDoc(sampleRef, data)
    setStatus('data updated')
    if (action === 'email') {
      setStatus('sending email to ' + email)
      const res = await fetch(`/api/email/${sampleId}`)
      if (res.status !== 200) {
        setStatus('email failed:' + res.status + ' ' + res.statusText)
        return
      }
    }
    if (action === 'download') {
      window.open(`/api/pdf/${sampleId}`, '_blank')
    }
    if (complete) {
      await updateDoc(sampleRef, {
        status: pcr ? 'registered' : 'completed'
      })
    }
    clearStatus()
  }

  function isoToLocal(params) {
    return moment(params.row.SAMPLE_DATE_VALUE).format('DD/MM/YYYY HH:mm')
  }

  const columns = [
    {
      field: 'SAMPLE_DATE_VALUE', headerName: 'Appointment', flex: 1, valueGetter: isoToLocal
    },
    { field: 'providerId', headerName: 'Client', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'EMAIL', headerName: 'Email', flex: 1 },
    { field: 'TYPE_TEST', headerName: 'Test Type', flex: .5 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (({ row }) => [
        <GridActionsCellItem showInMenu icon={<DynamicFormOutlinedIcon/>} label="Process"
                             onClick={() => openProcess(row)}/>,
        <GridActionsCellItem showInMenu icon={<EditOutlinedIcon/>} label="Edit" onClick={() => edit(row)}/>,
        <GridActionsCellItem
          showInMenu
          icon={<Delete/>}
          label="Remove record"
          onClick={() => handleDeleteDialog(row)}
        />,
      ])
    },
  ]

  return <Fragment>
    <Pane>
      <Title>Pre-Registered</Title>
      <WhenReady loading={loading} error={error} data={samples}>
        {samples && samples.docs && samples.docs.length && <Grid samples={samples} columns={columns}/> || <div></div>}
      </WhenReady>
      <Box style={{ width: '100%' }}>
        {status}
      </Box>
    </Pane>
    {processOpen && <ProcessDialog open={processOpen} onConfirm={process}
                                   pcr={(selectedRow && selectedRow.TYPE_TEST_VALUE || '').includes('pcr')}
                                   handleClose={closeProcess}
                                   initialForeNames={selectedRow && selectedRow.FORENAMES || ''}
                                   initialSurname={selectedRow && selectedRow.SURNAME || ''}
                                   initialSampleDate={selectedRow && selectedRow.SAMPLE_DATE_VALUE || new Date()}
                                   initialEmail={selectedRow && selectedRow.EMAIL || ''}/>}

    {openDeleteDialog && <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
      <DialogTitle>Delete record?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
        <Button onClick={() => {
          handleCloseDeleteDialog()
          handleDelete(selectedRow.id)
        }} autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>}
  </Fragment>
}

function ProcessDialog({
                         open,
                         onConfirm,
                         initialEmail,
                         initialSampleDate,
                         initialForeNames,
                         initialSurname,
                         handleClose,
                         pcr = false
                       }) {
  const [email, setEmail] = useState(initialEmail)
  const [result, setResult] = useState('negative')
  const [barcode, setBarcode] = useState('')
  const [sampleDate, setSampleDate] = useState(initialSampleDate)
  const [foreNames, setForeNames] = useState(initialForeNames)
  const [surname, setSurname] = useState(initialSurname)
  const [sampleMethod, setSampleMethod] = useState('medic-administered')

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Process Sample</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1em', padding: '1em' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
          <TextField
            label="Forenames"
            sx={{ flex: 1 }}
            value={foreNames}
            onChange={(e) => setForeNames(e.target.value)}
          />
          <TextField
            label="Surname"
            sx={{ flex: 1 }}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </Box>
        <DateTimePicker
          label="Sample Date"
          inputFormat="DD/MM/YYYY HH:mm"
          renderInput={(props) => <TextField {...props}/>}
          value={sampleDate ? moment(sampleDate) : null}
          onChange={(newValue) => {
            let v = newValue.toISOString()
            setSampleDate(v)
          }}
        />
        <Box style={{ display: 'flex' }}>


          <FormRadioGroupStatic label="Sample Method" onChange={setSampleMethod} value={sampleMethod}
                                options={SAMPLE_METHODS}/>
          {!pcr && <FormRadioGroupStatic label="Result" onChange={setResult} value={result} options={RESULT_TYPES}/>}


          <TextField
            label="Barcode"
            sx={{ width: '10em' }}
            value={barcode}
            onChange={(e) => setBarcode(e.target.value.toUpperCase())}
          />

        </Box>
        {!pcr &&
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />}


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {!pcr && <Fragment><Button onClick={() => {
          handleClose()
          onConfirm({ result, barcode, sampleMethod, email, sampleDate, foreNames, surname, action: 'email', pcr })
        }}>Send</Button>
          <Button onClick={async () => {
            await onConfirm({
              result,
              barcode,
              sampleMethod,
              email,
              sampleDate,
              foreNames,
              surname,
              action: 'download',
              complete: false,
              pcr
            })
          }}>Download</Button></Fragment>}
        <Button onClick={() => {
          handleClose()
          onConfirm({ result, barcode, sampleMethod, email, pcr, sampleDate, foreNames, surname, })
        }}>Mark as {pcr ? 'Registered' : 'Completed'}</Button>

      </DialogActions>
    </Dialog>

  )
}
