/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment } from 'react'
import { FormRadioGroupStatic, Pane, WhenNotEmpty } from './ReactUtils'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { collection, limit, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from './firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { WhenReady } from './AccessDenied'
import { RESULT_TYPES, SAMPLE_METHODS } from './Workflows'

function VerifyLFTForm({ sample }) {
  const data = sample && sample.data() || {}
  const [sampleMethod, setSampleMethod] = React.useState(data.SAMPLE_METHOD_VALUE || 'medic-reviewed')
  const [action, setAction] = React.useState('email')
  const [status, setStatus] = React.useState('')
  const [result, setResult] = React.useState(data.RESULTS_VALUE || (data.RESULTS && data.RESULTS.toLowerCase()) || '')

  function clearStatus() {
    setTimeout(() => {
      setStatus('')
    }, 1000)
  }

  async function confirm() {
    console.log('confirm:', sample)
    await updateDoc(sample.ref, {
      SAMPLE_METHOD: SAMPLE_METHODS[sampleMethod],
      SAMPLE_METHOD_VALUE: sampleMethod,
      RESULTS: RESULT_TYPES[result],
      RESULTS_VALUE: result
    })
    setStatus('data updated')
    if (action === 'email') {
      setStatus('sending email to ' + data.EMAIL)
      const res = await fetch(`/api/email/${data.sampleId}`)
      if (res.status !== 200) {
        setStatus('email failed:' + res.status + ' ' + res.statusText)
        return
      }
    } else if (action === 'download') {
      window.open(`/api/pdf/${data.sampleId}`, '_blank')
    }
    await updateDoc(sample.ref, {
      status: 'completed'
    })
    clearStatus()
  }

  function fail() {
  }

  const photoPath = `/api/samples/${data.sampleId}/photo`
  return <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>

    <TextField label="Name" value={data.FULLNAME} sx={{ width: '30em' }}/>
    <TextField label="Email" value={data.EMAIL} sx={{ width: '30em' }}/>
    <TextField label="Barcode" value={data.BARCODE} sx={{ width: '10em' }}/>

    <FormRadioGroupStatic label="Result" onChange={setResult} value={result}
                          options={RESULT_TYPES}/>
    <FormRadioGroupStatic label="Sample Method" onChange={setSampleMethod} value={sampleMethod}
                          options={SAMPLE_METHODS}/>
    <FormControl>
      <FormLabel>Action</FormLabel>
      <RadioGroup value={action} onChange={e => setAction(e.target.value)}>
        <FormControlLabel value="email" control={<Radio/>} label="Send Email"/>
        <FormControlLabel value="download" control={<Radio/>} label="Download and Complete"/>
      </RadioGroup>
    </FormControl>

    <Box style={{ display: 'flex', gap: '10px', alignItems: 'start' }}>
      <Button variant={'contained'} onClick={fail}>Fail</Button>
      <Button variant={'contained'} onClick={confirm}>Confirm</Button>
    </Box>

    <Box>
      <a href={photoPath}>
        <img style={{ maxWidth: '100%' }} src={photoPath}/>
      </a>
    </Box>
    <Box style={{ width: '100%' }}>
      {status}
    </Box>
  </Box>
}

export function VerifyLFT() {
  const q = query(
    collection(firestore, 'samples'),
    where('status', '==', 'pending-verification'),
    limit(1))

  const [samples, loading, error] = useCollection(q)

  return <Fragment>
    <WhenReady loading={loading} error={error}>
      <Pane>
        <WhenNotEmpty data={samples} message={'No verification pending'}>
          {samples && samples.docs && samples.docs.map(sample => <VerifyLFTForm key={sample.id} sample={sample}/>)}
        </WhenNotEmpty>
      </Pane>
    </WhenReady>
  </Fragment>
}
