import * as React from 'react'
import { Pane, SimpleDataGridEditor } from './ReactUtils'

export function TestTypes() {
  const columns = [
    { field: 'id', hide: true },
    { field: 'ourTestType', headerName: 'Our Test Type', width: 300, editable: true },
    { field: 'nhsTestType', headerName: 'NHS Test Type', width: 150, editable: true }
  ]
  return <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Pane sx={{ width: '600px' }}>
      <SimpleDataGridEditor
        title="Test Types"
        collectionPath="testTypes"
        mainField="ourTestType"
        columns={columns}/>
    </Pane>
  </div>
}
