import { Pane } from './ReactUtils'
import Title from './Title'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import { Box } from '@mui/material'

export function Training() {
  return <Pane><Title>Training</Title>
    <Box className="wip">
      <ConstructionOutlinedIcon/>
      Work in progress.
    </Box>
  </Pane>
}
