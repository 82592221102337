import { auth, firebaseApp, firestore } from './firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Loading } from './Loading'
import { Login } from './Login'
import { doc, getFirestore, increment, serverTimestamp, setDoc } from 'firebase/firestore'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import * as React from 'react'
import { Fragment, useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { Logout } from '@mui/icons-material'
// import { Main } from './Main'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { postJson } from './util'
import { Main } from './Main'
// import { ThemeProvider } from '@mui/styles'
// import { createTheme } from '@mui/material/styles'
//
// const theme = createTheme({ palette: { mode: 'dark' } })

function App() {
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (user) {
      auth.currentUser.getIdToken().then(token => {
        postJson('/token', { idToken: token }).then(async res => {
          console.log('Server response: ', await res.json())
        })
      })
    }
  }, [user])

  return (
    // <ThemeProvider theme={theme}>
    <div className="app">
      {user ? <MainLoggedIn/> : loading ? <Loading/> : <Login/>}
    </div>
    // </ThemeProvider>
  )
}

function MainLoggedIn() {
  const [data, loading, error] = useDocumentDataOnce(doc(firestore, 'settings', 'global'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (data && data.muiXProLicense) {
      LicenseInfo.setLicenseKey(data.muiXProLicense)
    }
  }, [data])

  if (error && error.code === 'permission-denied') {
    return <NotAuthorized/>
  }

  return <Fragment>
    {/* eslint-disable-next-line no-mixed-operators */}
    {error && <strong>Error: {JSON.stringify(error)}</strong> || loading && <Loading/> || data &&
      <Main/> || <span>god knows</span>}
  </Fragment>
}

function NotAuthorized() {
  useEffect(() => {
    setDoc(doc(getFirestore(firebaseApp), 'unknownUsers', auth.currentUser.email), {
      time: serverTimestamp(),
      count: increment(1)
    }, { merge: true })
  }, [])
  return <div className={'singleMessagePanel'}>
    <Typography>You are not authorized to access this system. Please ask the administrator.</Typography>
    <Button variant="contained" onClick={() => auth.signOut()} style={{ gap: '10px' }}><Logout/> Logout</Button>
  </div>
}

export default App
