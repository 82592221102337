export async function postJson(path, json) {
  return await fetch(path, {
    method: 'POST', headers: {
      'Accept': 'application/json', 'Content-Type': 'application/json'
    }, body: JSON.stringify(json)
  })
}

export function debounce(cb, ms) {
  let timeout
  let lastArgs
  const f = (...args) => {
    lastArgs = args
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      timeout = undefined
      cb(...lastArgs)
      lastArgs = undefined
    }, ms)
  }

  f.callNow = () => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
    cb(...lastArgs)
    lastArgs = undefined
  }

  return f
}
