import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { auth, firestore, useUserDetails } from './firebase'
import { collection, doc, query, where } from 'firebase/firestore'
import { Settings } from '@mui/icons-material'
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import prk from '../package.json'
import { styled } from '@mui/material/styles'
import { Badge } from '@mui/material'
import { Privileged } from './ReactUtils'

const { version } = prk

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 116,
    top: 15
  },
}))

function PendingBadge({ children }) {
  const pendingVerificadtionQuery = query(
    collection(firestore, 'samples'),
    where('status', '==', 'pending-verification'))

  const [pendingVerification] = useCollection(pendingVerificadtionQuery)

  let pendingVerificationCount = pendingVerification && pendingVerification.docs && pendingVerification.docs.length

  return pendingVerificationCount
    && <StyledBadge badgeContent={pendingVerificationCount} color="primary">{children}</StyledBadge> || children

}

export function DrawerMenu() {
  const [user] = useUserDetails()
  const [data, loading, error] = useDocument(doc(firestore, 'users', auth.currentUser.email),
    { snapshotListenOptions: { includeMetadataChanges: true } })

  if (!user) {
    return <div/>
  }

  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    {!error && !loading && data && data.data().admin && <MenuItem text="Admin" icon={<Settings/>} to={'/admin'}/>}

    <Privileged access="clinic">
      {user.can('review-samples') &&
        <PendingBadge><MenuItem text="Clinic" icon={<LocalHospitalOutlinedIcon/>} to={'/clinic'}/></PendingBadge> ||
        <MenuItem text="Clinic" icon={<LocalHospitalOutlinedIcon/>} to={'/clinic'}/>}
    </Privileged>
    <Privileged access="lab">
      <MenuItem text="Lab" icon={<BiotechOutlinedIcon/>} to={'/lab'}/>
    </Privileged>
    <Privileged access="training">
      <MenuItem text="Training" icon={<SchoolOutlinedIcon/>} to={'/training'}/>
    </Privileged>
    <footer style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
      <span>{version}</span>
    </footer>
  </div>

}

function MenuItem(props) {
  const navigate = useNavigate()
  return <ListItem button onClick={() => navigate(props.to)}>
    <ListItemIcon>
      {props.icon}
    </ListItemIcon>
    <ListItemText primary={props.text}/>
  </ListItem>
}
