import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { CACHE_SIZE_UNLIMITED, doc, enableIndexedDbPersistence, getDoc, initializeFirestore, onSnapshot } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as React from 'react'
import { useEffect } from 'react'

const production = {
  apiKey: 'AIzaSyB4VbXZ5327h82bqct5DPeaLWii_gA3nr0',
  authDomain: 'lims-82ec4.firebaseapp.com',
  projectId: 'lims-82ec4',
  storageBucket: 'lims-82ec4.appspot.com',
  messagingSenderId: '86578108144',
  appId: '1:86578108144:web:df2345f1515c7e8f335435',
  measurementId: 'G-W3K3LVBP9T'
}

const staging = {
  apiKey: 'AIzaSyDZYEosstz3nfC3mLG46wd1VF6SblLQ8ao',
  authDomain: 'suite-staging-c02b5.firebaseapp.com',
  projectId: 'suite-staging-c02b5',
  storageBucket: 'suite-staging-c02b5.appspot.com',
  messagingSenderId: '675662661972',
  appId: '1:675662661972:web:1c1aee1fb309cfd48020c1'
}

export function isProductionEnvironment() {
  return window.location.host === 'suite.mitmark-health.com'
}

const firebaseConfig = isProductionEnvironment() ? production : staging

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)

export const db = getDatabase(firebaseApp)

export const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
})

enableIndexedDbPersistence(firestore)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.error('Multiple tabs open, persistence can only be enabled in one tab at a a time.')
    } else if (err.code === 'unimplemented') {
      console.error('The current browser does not support all of the features required to enable persistence.')
    }
  })

const provider = new GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const analytics = getAnalytics()

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then(() => logEvent(analytics, 'logged in'))
}

let userDetailsHandler

export function useUserDetails() {
  const [user, loading, error] = useAuthState(auth)
  const [result, setResult] = React.useState([null, true, false])

  useEffect(async () => {
    if (userDetailsHandler) {
      if (result[0] !== userDetailsHandler) {
        setResult([userDetailsHandler, false, false])
      }
      return
    }
    if (error) {
      setResult([null, false, error])
      return
    }
    if (loading) {
      setResult([null, loading, false])
      return
    }
    if (!user) {
      return
    }
    const { email } = auth.currentUser

    onSnapshot(doc(firestore, 'users', email), userDoc => {
      const userData = userDoc.data()
      const { accessRights, dashboard = '/', admin } = userData

      console.log('----------------> useUserDetails:', email, userData)

      function canAccess(right) {
        if (admin) {
          return true
        }
        if (!accessRights) {
          // TODO: user groups
          return true
        }
        return accessRights.includes(right)
      }

      userDetailsHandler = {
        can: right => {
          let r = canAccess(right)
          if (!r) {
            console.log('access denied to', right)
          }
          return r
        }, dashboard, admin
      }

      setResult([userDetailsHandler, false, false])

    })
  }, [user, loading, error])

  // useEffect(() => {
  //   if (rights && rights.accessRights) {
  //     const { accessRights } = rights
  //     const { uid } = user
  //     const access = accessRights[uid]
  //     if (access) {
  //       const { access } = access
  //       if (access.includes('admin')) {
  //         return
  //       }
  //       if (access.includes('biotech')) {
  //         return
  //       }
  //       if (access.includes('hospital')) {
  //         return
  //       }
  //       if (access.includes('school')) {
  //         return
  //       }
  //       if (access.includes('user')) {
  //         return
  //       }
  //       navigate('/')
  //     }
  //   }
  // }, [user, rights])

  return result
}
