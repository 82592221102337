export const WORKFLOW = {
  'ready-to-import': 'Ready to Import',
  'import-fail': 'Import Fail',
  'pending-verification': 'Pending Verification',
  'result-verified': 'Result Verified',
  'failed-verification': 'Failed Verification',
  'registered': 'Registered',
  'available': 'Available',
  'received': 'Received',
  'running': 'Running',
  'completed': 'Completed',
  'void': 'Void',
  'error': 'Error'
}

export const SAMPLE_METHODS = {
  '': 'N/A',
  'medic-administered': 'Medic Administered',
  'medic-supervised': 'Medic Supervised',
  'medic-reviewed': 'Medic Reviewed',
  'self-swabbed': 'Self Swabbed'
}

export const TYPE_TESTS = {
  'day 2 lft': 'Day 2 LFT',
  'fit to fly lft': 'Fit to Fly LFT',
  'fit to fly pcr': 'Fit to Fly PCR'
}

export const RESULT_TYPES = {
  'negative': 'Negative',
  'positive': 'Positive',
  'inconclusive': 'Inconclusive'
}

export const SAMPLE_SCHEMA = {
  Main: {
    BARCODE: { editable: false, label: 'Barcode' },
    providerId: { label: 'Provider' },
    status: { label: 'Status', type: 'select', options: WORKFLOW, separateValueField: false },
  },
  Address: {
    HOME_ADDRESS: { label: 'Address - 1st line', width: '30em' },
    SECOND_LINE_OF_ADDRESS: { label: 'Address - 2nd line', width: '30em' },
    CITY: { label: 'City' },
    POSTCODE: { label: 'Postcode' },
  },
  Contact: {
    FORENAMES: { label: 'Forenames', width: '30em' },
    SURNAME: { label: 'Surname' },
    PHONE: { label: 'Phone', },
    EMAIL: { label: 'Email', width: '30em' },
  },
  'Patient Details': {
    DOB: { type: 'date', label: 'Date of Birth' },
    ID_TYPE: { label: 'Id Type' },
    ID_NUMBER: { label: 'Id Number' },
    NHS_NUM: { label: 'NHS Number' },
    ETHNICITY: { label: 'Ethnicity' },
    SEX: { label: 'Sex' },
  },
  Sample: {
    TYPE_TEST: { label: 'Test Type', type: 'select', options: TYPE_TESTS },
    RESULTS: { label: 'Result', type: 'select', options: RESULT_TYPES },
    TRACKING_NUMBER: { label: 'Tracking Number' },
    VACCINATION_STATUS: { label: 'Vaccination Status' },
    SAMPLE_METHOD: { label: 'Sample Method', type: 'select', options: SAMPLE_METHODS },
    SAMPLE_DATE: { type: 'datetime', label: 'Appointment/Sample Date' },
    PHOTO_URL: { type: 'image' }
  }
}
