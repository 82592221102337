/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Pane, WhenNotEmpty } from './ReactUtils'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { collection, doc, writeBatch } from 'firebase/firestore'
import { firestore } from './firebase'
import { WhenReady } from './AccessDenied'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import SendIcon from '@mui/icons-material/Send'
import Title from './Title'
import { transformSample } from 'lims-server/src/util/Transforms.js'

const TYPES = ['day 2 lft', 'fit to fly lft']
const supportedTypes = ({ TYPE_TEST }) => typeof TYPE_TEST === 'string' && TYPES.includes(TYPE_TEST.trim().toLowerCase())

const Grid = React.memo(({ entries, columns }) => {
  return <DataGridPro disableColumnMenu
                      autoHeight={true}
                      rows={entries.map((a, index) => ({
                        ...a,
                        id: a.BARCODE,
                        name: `${a.FORENAMES} ${a.SURNAME}`,
                        index
                      }))}
                      getRowId={row => row.index}
                      columns={columns}
                      getRowClassName={(params) => `row-status--${params.row.status}`}
                      hideFooter={true}/>
}, (prevProps, nextProps) => {
  return prevProps.entries.length === nextProps.entries.length &&
    prevProps.entries.every((a, index) => a.status === nextProps.entries[index].status)
})

const Input = React.memo(({ clients, providerId, setProviderId, text, setText }) => {
  return <WhenNotEmpty data={clients}>
    <FormControl fullWidth sx={{ m: 1 }}>
      <InputLabel>Provider</InputLabel>
      <Select
        value={providerId}
        label="Provider"
        onChange={e => setProviderId(e.target.value)}>
        {clients && clients.docs.map(client => <MenuItem key={client.id}
                                                         value={client.id}>{client.data().name}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl fullWidth sx={{ m: 1 }}>
      <TextField label="Spreadsheet Copy-And-Paste" rows={5} multiline={true}
                 onChange={e => setText(e.target.value)}
                 value={text}
                 inputProps={{ style: { fontSize: '0.5em', whiteSpace: 'nowrap' } }}
      />
    </FormControl>
  </WhenNotEmpty>
}, (prevProps, nextProps) => {
  return prevProps.providerId === nextProps.providerId &&
    prevProps.text === nextProps.text
})

export function LftCertMaker() {
  const [providerId, setProviderId] = useState('mitmark')
  const [text, setText] = useState('')
  const [entries, setEntries] = useState([])
  const [status, setStatus] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [to, setTo] = useState('')
  const [clients, loading, error] = useCollection(collection(firestore, 'clients'))

  const [provider] = useDocument(doc(firestore, 'clients', providerId || 'mitmark'))

  useEffect(() => {
    if (status) {
      console.log('Status changed:', status)
    }
  }, [status])

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    setText0(text && providerId && text || '').catch(console.error)
  }, [text, providerId])

  async function setText0(text) {
    if (!provider || !provider.data) {
      setStatus('Please select a provider')
      return
    }
    const { spreadsheetFields } = provider.data()
    if (!spreadsheetFields) {
      setStatus('No Spreadsheet Fields found for that provider, please fix this in provider settings')
      return
    }
    setStatus('parsing...')
    const samplesP = text.split('\n').map(async line => {
      let fields = line.split('\t')

      const entry = Object.fromEntries(spreadsheetFields.split('\n').map((fieldRaw, i) => [fieldRaw.trim(), fields[i]]))
      entry.providerId = providerId
      await transformSample(entry)

      return entry
    })
    const samples = (await Promise.all(samplesP)).filter(supportedTypes).filter(a => a.BARCODE)

    // setEntries(samples.map((a, id) => ({ ...a, id, name: `${a.FORENAMES} ${a.SURNAME}`, status: 'pending' })))

    function arrayGroupBy(array, key) {
      return array.reduce((acc, item) => {
        const keyValue = item[key]
        const keyValueString = keyValue.toString()
        if (!acc[keyValueString]) {
          acc[keyValueString] = []
        }
        acc[keyValueString].push(item)
        return acc
      }, {})
    }

    const byBarcode = arrayGroupBy(samples, 'BARCODE')
    setEntries([])

    setStatus('found ' + samples.length + ' samples, uploading...')
    let all = []
    setTimeout(async () => {
      try {
        const batch = writeBatch(firestore)

        for (const [barcode, sameBarcode] of Object.entries(byBarcode)) {
          if (sameBarcode.length > 1) {
            console.log('-----------> duplicate:', barcode, sameBarcode)
            for (const entry of sameBarcode) {
              entry.status = 'duplicate'
            }
          } else {
            const [entry] = sameBarcode
            const ref = doc(firestore, `samples`, barcode)
            entry.status = 'result-verified'
            entry.sampleId = barcode
            batch.set(ref, entry, { merge: true })
          }

          all = [...all, ...sameBarcode]
        }
        console.log('---------> sending...')
        await batch.commit()
        setEntries(all)
        console.log('---------> done:')
      } catch (e) {
        console.error(e)
      } finally {
        setStatus('')
      }
    })
  }

  const columns = [
    { field: 'BARCODE', headerName: 'Barcode', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'EMAIL', headerName: 'Email', flex: 1 },
    { field: 'RESULTS', headerName: 'Results', flex: .5 },
    { field: 'TYPE_TEST', headerName: 'Test Type', flex: .5 },
    { field: 'status', headerName: 'Status', flex: .5 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (({ id, row }) => [
        <GridActionsCellItem showInMenu icon={<NoteAddIcon/>} label="Download PDF" onClick={() => {
          console.log('------------->', row.BARCODE)
          window.open(`/api/pdf/${row.BARCODE}`, '_blank')
        }}/>,
        <GridActionsCellItem showInMenu icon={<SendIcon/>} label="Send E-Mail" onClick={() => sendEmail(id)}/>,
      ])
    },
  ]

  async function sendEmail(id) {
    const row = entries[id]
    setEntries([...entries.slice(0, id), { ...row, status: 'sending' }, ...entries.slice(id + 1)])
    console.log('sendEmail', row.BARCODE)
    const res = await fetch(`/api/email/${row.BARCODE}?to=${to}`)
    let status = 'sent'
    let reason = undefined
    if (res.status !== 200) {
      status = 'error'
      reason = res.statusText
      console.log('-------> error:', res.status, res.statusText)
    }
    setEntries([...entries.slice(0, id), { ...row, status, reason }, ...entries.slice(id + 1)])
    console.log('sendEmail', row.BARCODE, 'done')
  }

  async function sendEmails() {
    for (let i = 0; i < entries.length; i++) {
      await sendEmail(i)
    }
  }

  return <Fragment>
    <Pane>
      <WhenReady loading={loading} error={error}>
        <Input text={text} setText={setText} clients={clients} providerId={providerId} setProviderId={setProviderId}/>
      </WhenReady>
    </Pane>
    {inProgress && <div className={'shortMessagePanel'}>
      <CircularProgress/>
    </div>}
    {status && <div className={'shortMessagePanel'}>
      <Typography textAlign="center">{status}</Typography>
    </div>}
    <WhenNotEmpty data={entries}>
      <Pane>
        <Title>Samples parsed</Title>
        <Grid entries={entries} columns={columns}/>

        <TextField label="Override Destination Email"
                   onChange={e => setTo(e.target.value)}
                   value={to}
                   fullWidth
        />
        <Button variant={'contained'} onClick={sendEmails}>Send Emails</Button>
      </Pane>
    </WhenNotEmpty>
  </Fragment>
}
