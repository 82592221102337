/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useState } from 'react'
import { Pane, WhenNotEmpty } from './ReactUtils'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import moment from 'moment'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection } from 'firebase/firestore'
import { firestore } from './firebase'
import { WhenReady } from './AccessDenied'

const DEFAULT_DAYS_FOR_ARRIVAL_AFTER_SALES = 3.15
const HEADER = 'testBookingDate,testBookingReference,bookingTestType,arrivalDateUK,salesStatus'

export function GenerateCSV() {
  const [providerId, setProviderId] = useState('')
  const [text, setText] = useState('')
  const [files, setFiles] = useState([])
  const [dateFrom, setDateFrom] = useState('')
  const [clients, loading, error] = useCollection(collection(firestore, 'clients'))

  function process() {
    setTimeout(_process)
  }

  function fixDateFormat(a) {
    let parts = a.split('/')

    if (parts.length === 3) {
      let m = moment(a, 'DD/MM/YYYY')
      if (!m.isValid()) {
        m = moment(a, 'MM/DD/YYYY')
      }
      return m.format('DD/MM/YYYY')
    }
    return a
  }

  function _process() {

    const products = {
      'Day 2/5/8 PCR Package': { base: 'PCR028' },
      'Day 2/8 PCR Package': { base: 'PCR028' },
      'Day 2 LFT': { base: 'LFD002' },
      'Fit to Fly / Day 2 LFT Package': { base: 'LFD002' },
      'Fit to Fly / Day 2 PCR Package': { base: 'PCR002' },
      'Fit to Fly LFT': { base: 'LFD000' },
      'Fit to Fly PCR': { base: 'PCR000' },
      'Day 2 PCR Test': { base: 'PCR002' },
      'Day 5 Test to Release PCR Test': { base: 'PCR005' },
    }

    const vaccinatedMap = {
      Yes: 'V',
      No: 'N'
    }

    const entries = text.split('\n').map(line => {
      const [salesDate, , testBookingReference, product, vaccinated, arrivalDate] = line.split('\t')
      if (!salesDate) {
        return
      }
      let testBookingDateMoment = moment(salesDate, 'DD/MM/YYYY HH:mm')
      const testBookingDate = testBookingDateMoment.format('YYYY-MM-DD HH:mm:ss')
      let p = products[product]
      if (!p) {
        console.log('Unknown product:', product)
        return
      }
      let v = vaccinatedMap[vaccinated]
      if (!v) {
        console.log('Unknown or absent vaccinated status:', line)
        return
      }
      let arrivalDateMoment = moment(arrivalDate, 'DD/MM/YYYY')
      if (!arrivalDateMoment.isValid()) {
        console.log('Unknown or absent arrival date:', line)
        return
      }
      const bookingTestType = p.base + 'N' + v
      const arrivalDateUK = arrivalDateMoment.format('YYYY-MM-DD')
      const salesStatus = 'Active'

      return {
        testBookingDateMoment,
        csv: [
          testBookingDate,
          testBookingReference,
          bookingTestType,
          arrivalDateUK,
          salesStatus]
      }
    }).filter(a => a)

    const byDate = entries.reduce((acc, next) => {
      const key = next.testBookingDateMoment.add(1, 'day').format('YYYY.MM.DD')

      const a = acc[key] = acc[key] || []
      a.push(next.csv)

      return acc
    }, {})

    const sorted = Object.keys(byDate).sort()
    let first = moment(sorted[0], 'YYYY.MM.DD')
    const last = moment(sorted[sorted.length - 1], 'YYYY.MM.DD').add(1, 'day')
    const files = []
    let count = 0
    while(first < last) {
      if (count++ > 1000) {
        return
      }
      let date = first.format('YYYY.MM.DD')
      const rows = byDate[date]
      let csvRows
      let fileName
      if (!rows) {
        csvRows = []
        fileName = `no_sales_data.csv`
      } else {
        csvRows = rows.map(row => row.join(','))
        csvRows.unshift(HEADER)
        fileName = `sales_data.csv`
      }
      files.push([`${providerId}_${date}_${fileName}`, csvRows])
      first = first.add(1, 'day')
    }

    // const files = Object.entries(byDate).map(([date, rows]) => {
    //   const a = rows.map(row => row.join(','))
    //   a.unshift(HEADER)
    //   return [`${providerId}_${date}_sales_data.csv`, a]
    // })
    setFiles(files)
  }

  function _process_pyser() {
    const entries = text.split('\n').map(line => {
      const [testBookingReference, , salesDate, arrivalDate, vaccinated] = line.split('\t')
      if (!salesDate || !arrivalDate || !vaccinated) {
        // eslint-disable-next-line array-callback-return
        return
      }
      let testBookingDateMoment = moment(salesDate, 'Do MMMM YYYY HH:mm a')
      const testBookingDate = testBookingDateMoment.format('YYYY-MM-DD HH:mm:ss')
      const bookingTestType = 'PCR002N' + (vaccinated === 'Yes' ? 'V' : 'N')
      let arrivalDateMoment = moment(arrivalDate, 'DD/MM/YYYY')
      if (!arrivalDateMoment.isBetween('2021-10-10', '2022-02-12')) {
        arrivalDateMoment = moment(arrivalDate, 'MM/DD/YYYY')
        if (!arrivalDateMoment.isBetween('2021-10-10', '2022-02-12')) {
          if (arrivalDateMoment.isBetween('2020-10-10', '2021-10-01')) {
            arrivalDateMoment.add(1, 'year')
          }
          if (!arrivalDateMoment.isBetween('2021-10-10', '2022-02-12')) {
            arrivalDateMoment = testBookingDateMoment.add(DEFAULT_DAYS_FOR_ARRIVAL_AFTER_SALES, 'days')
          }
        }
      }
      const arrivalDateUK = arrivalDateMoment.format('YYYY-MM-DD')
      const salesStatus = 'Active'

      return {
        testBookingDateMoment,
        csv: [
          testBookingDate,
          testBookingReference,
          bookingTestType,
          arrivalDateUK,
          salesStatus]
      }
    }).filter(a => a)

    const byDate = entries.reduce((acc, next) => {
      const key = next.testBookingDateMoment.add(1, 'day').format('YYYY.MM.DD')

      const a = acc[key] = acc[key] || []
      a.push(next.csv)

      return acc
    }, {})
    const files = Object.entries(byDate).map(([date, rows]) => {
      const a = rows.map(row => row.join(','))
      a.unshift(HEADER)
      return [`${providerId}_${date}_sales_data.csv`, a]
    })
    setFiles(files)
  }

  return <Pane>
    <WhenReady loading={loading} error={error}>
      <WhenNotEmpty data={clients}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel>Provider</InputLabel>
          <Select
            value={providerId}
            label="Provider"
            onChange={e => setProviderId(e.target.value)}>
            {clients && clients.docs.map(client => <MenuItem key={client.id}
                                                             value={client.data().filePrefix}>{client.data().name}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            value={dateFrom}
            label="Date range"
            onChange={e => setDateFrom(e.target.value)} onBlur={e => setDateFrom(fixDateFormat(e.target.value))}/>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField label="Spreadsheet Copy-And-Paste" rows={15} multiline={true}
                     onChange={e => setText(e.target.value)}
                     value={text}/>
        </FormControl>
        <Button variant={'contained'} onClick={process}>Process</Button>
        {files.length ? <Files provider={providerId} files={files}/> : <></>}


      </WhenNotEmpty>
    </WhenReady>


  </Pane>
}

function Files({ provider, files }) {
  function download() {
    const zip = new JSZip()
    files.forEach(([file, rows]) => zip.file(file, rows.join('\n')))

    zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, `${provider.toLowerCase()}.zip`)
    })
  }

  return <Fragment>
    <Typography>{files.length} files to download,
      total {files.reduce((acc, [, rows]) => acc + rows.length - 1, 0)} records</Typography>
    <Button variant={'contained'} onClick={download}>Download</Button>
  </Fragment>
}
