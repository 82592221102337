/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { AccordionGroup, Pane } from './ReactUtils'
import { Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { doc, setDoc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { jsPDF } from 'jspdf'
import JsBarcode from 'jsbarcode'
import { BarcodeQuestion } from './Clients'

const SmtpDetailsEdit = React.memo(({ smtpDetails, onChange }) => {
  return <AccordionGroup label="SMTP Details">
    <TextField
      label="From Email"
      sx={{ width: '20em' }}
      value={smtpDetails.from || ''}
      onChange={(e) => onChange({ ...smtpDetails, from: e.target.value })}
    />
    <TextField
      label="Host"
      sx={{ width: '20em' }}
      value={smtpDetails.host}
      onChange={(e) => onChange({ ...smtpDetails, host: e.target.value })}
    />
    <TextField
      sx={{ width: '6em' }}
      label="Port"
      value={smtpDetails.port}
      onChange={(e) => onChange({ ...smtpDetails, port: e.target.value })}
    />
    <TextField
      label="Username"
      sx={{ width: '20em' }}
      value={smtpDetails.username}
      onChange={(e) => onChange({ ...smtpDetails, username: e.target.value })}
    />
    <TextField
      label="Password"
      value={smtpDetails.password}
      type={'password'}
      onChange={(e) => onChange({ ...smtpDetails, password: e.target.value })}
    />
  </AccordionGroup>
}, (prev, next) => {
  return prev.smtpDetails.from === next.smtpDetails.from
    && prev.smtpDetails.host === next.smtpDetails.host
    && prev.smtpDetails.port === next.smtpDetails.port
    && prev.smtpDetails.username === next.smtpDetails.username
    && prev.smtpDetails.password === next.smtpDetails.password
})

const ContactDetailsEdit = React.memo(({ contactDetails, onChange }) => {
  return <AccordionGroup label="Contact Details">
    <TextField
      label="Point of Contact"
      sx={{ width: '20em' }}
      value={contactDetails.poc}
      onChange={(e) => onChange({ ...contactDetails, poc: e.target.value })}
    />
    <TextField
      label="Email"
      sx={{ width: '20em' }}
      value={contactDetails.email}
      onChange={(e) => onChange({ ...contactDetails, email: e.target.value })}
    />
    <TextField
      label="Phone"
      sx={{ width: '20em' }}
      value={contactDetails.phone}
      onChange={(e) => onChange({ ...contactDetails, phone: e.target.value })}
    />
    <TextField
      label="Address"
      sx={{ width: '20em' }}
      value={contactDetails.address}
      onChange={(e) => onChange({ ...contactDetails, address: e.target.value })}
      rows={5}
      multiline={true}
    />
    <TextField
      label="Notes"
      sx={{ width: '40em' }}
      value={contactDetails.notes || ''}
      onChange={(e) => onChange({ ...contactDetails, notes: e.target.value })}
      rows={8}
      multiline={true}
    />
  </AccordionGroup>
}, (prev, next) => {
  return prev.contactDetails.poc === next.contactDetails.poc
    && prev.contactDetails.email === next.contactDetails.email
    && prev.contactDetails.phone === next.contactDetails.phone
    && prev.contactDetails.address === next.contactDetails.address
    && prev.contactDetails.notes === next.contactDetails.notes
})

const BarcodeEdit = React.memo(({ clientRef, nextBarcode, barcodePrefix, setBarcodePrefix, setNextBarcode }) => {
  const [generateBarcodeOpen, setGenerateBarcodeOpen] = useState(false)

  async function generateBarcodes({ count, repeats }) {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [1.06, 0.91]
    })

    const width = pdf.internal.pageSize.getWidth()
    const height = pdf.internal.pageSize.getHeight()

    const svg = document.createElement('svg')

    for (let offset = 0; offset < count; offset++) {
      const barcodeNumber = nextBarcode + offset
      const s = barcodePrefix + barcodeNumber.toString().padStart(7, '0')
      JsBarcode(svg, s, { format: 'CODE128' })

      for (let i = 0; i < repeats; i++) {
        (offset || i) && pdf.addPage()
        await pdf.svg(svg, { x: 0, y: 0, width, height })
      }
    }

    pdf.save('barcodes.pdf')

    await setDoc(clientRef, { nextBarcode: nextBarcode + count }, { merge: true })
  }

  function closeGenerateBarcodes() {
    setGenerateBarcodeOpen(false)
  }

  return <AccordionGroup label="Barcodes Printer">
    <TextField label="Barcode Prefix" value={barcodePrefix} onChange={e => setBarcodePrefix(e.target.value)}/>
    <TextField label="Next Barcode" value={nextBarcode} onChange={e => setNextBarcode(e.target.value)}/>
    <Box width={'100%'}>
      <Button onClick={() => setGenerateBarcodeOpen(true)}><QrCode2Icon/><Typography>Generate
        Barcodes</Typography></Button>
      <BarcodeQuestion open={generateBarcodeOpen} onConfirm={generateBarcodes}
                       handleClose={closeGenerateBarcodes}/>
    </Box>
  </AccordionGroup>
}, (prev, next) => {
  return prev.barcodePrefix === next.barcodePrefix
    && prev.nextBarcode === next.nextBarcode
})

const CertMakerEdit = React.memo(({ providerId, certMakerDetails, setCertMakerDetails }) => {
  const fonts = ['Montserrat-Medium', 'Arial-Bold']
  return <AccordionGroup label="Certificate Maker Configuration">
    <TextField
      label="Spreadsheet Fields"
      multiline={true}
      inputProps={{ style: { whiteSpace: 'nowrap' } }}
      rows={15}
      sx={{ width: '20em' }}
      value={certMakerDetails.spreadsheetFields}
      onChange={(e) => setCertMakerDetails({ ...certMakerDetails, spreadsheetFields: e.target.value })}
    />
    <TextField
      label="PDF Render Positions"
      multiline={true}
      rows={15}
      style={{ flexGrow: 1 }}
      inputProps={{ style: { whiteSpace: 'nowrap' } }}
      value={certMakerDetails.pdfRenderPositions}
      onChange={(e) => setCertMakerDetails({ ...certMakerDetails, pdfRenderPositions: e.target.value })}
    />
    <Box width={'100%'}>
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Font</InputLabel>
        <Select
          value={certMakerDetails.font}
          label="Font"
          onChange={e => setCertMakerDetails({ ...certMakerDetails, font: e.target.value })}>
          {fonts.map(font => <MenuItem key={font} value={font}>{font}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <TextField
          label="Color"
          sx={{ width: '10em' }}
          value={certMakerDetails.color}
          onChange={(e) => setCertMakerDetails({ ...certMakerDetails, color: e.target.value })}
        />
      </FormControl>
    </Box>
    <Box width={'100%'}>
      <Button onClick={() => fetch('/api/clear-certificates-cache?providerId=' + providerId)}><RefreshIcon/><Typography>Clear
        Certificates Cache</Typography></Button>
    </Box>
  </AccordionGroup>
}, (prev, next) => {
  return prev.certMakerDetails.spreadsheetFields === next.certMakerDetails.spreadsheetFields
    && prev.certMakerDetails.pdfRenderPositions === next.certMakerDetails.pdfRenderPositions
    && prev.certMakerDetails.font === next.certMakerDetails.font
    && prev.certMakerDetails.color === next.certMakerDetails.color
})

const EmailTemplates = React.memo(({ providerId, hooks }) => {
  const {
    emailTemplates,
    setEmailTemplates,
    certFileName,
    certEmailSubject,
    setCertFileName,
    setCertEmailSubject
  } = hooks
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const testTypes = Object.keys(emailTemplates).sort((a, b) => b.localeCompare(a))
  return <AccordionGroup label="Email Templates">
    <Box style={{ display: 'flex', width: '100%', flexFlow: 'column', gap: '1em' }}>
      <TextField
        label="File Name Template"
        value={certFileName}
        onChange={e => setCertFileName(e.target.value)}
      />
      <TextField
        label="Email Subject Template"
        value={certEmailSubject}
        onChange={e => setCertEmailSubject(e.target.value)}
      />
      <Box style={{ display: 'flex', width: '100%' }}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {testTypes.map((testType, index) => (
            <Tab key={index} label={testType}/>
          ))}
        </Tabs>
        {testTypes.map((testType, index) => (
          <TabPanel key={index} value={value} index={index}>
            <TestTypeEdit
              testType={emailTemplates[testType]}
              onChange={(templates) => {
                setEmailTemplates({ ...emailTemplates, [testType]: templates })
              }}
            />
          </TabPanel>
        ))}
      </Box>
    </Box>
  </AccordionGroup>

  function TestTypeEdit({ testType, onChange }) {
    const [value, setValue] = React.useState(0)

    const results = Object.keys(testType).sort((a, b) => b.localeCompare(a))
    return <Box>
      <Tabs
        value={value}
        onChange={(e, t) => setValue(t)}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {results.map((result, index) => (
          <Tab key={index} label={result}/>
        ))}
      </Tabs>
      {results.map((result, index) => (
        <TabPanel key={index} value={value} index={index}>
          <ResultEdit
            result={testType[result]}
            onChange={(template) => {
              onChange({ ...testType, [result]: template })
            }}
          />
        </TabPanel>
      ))}
    </Box>

  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        style={{ flexGrow: 1 }}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    )
  }

  function ResultEdit({ result, onChange }) {
    const [value, setValue] = React.useState(result)
    return <Box style={{ display: 'flex' }}>
      <TextField
        label="Email Template"
        multiline={true}
        rows={10}
        value={value}
        style={{ flexGrow: 1 }}
        inputProps={{ style: { fontSize: '0.8em' } }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onChange(value)}
      />
    </Box>
  }

}, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next)
})

export function Client() {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [barcodePrefix, setBarcodePrefix] = useState('')
  const [nextBarcode, setNextBarcode] = useState('')
  const [filePrefix, setFilePrefix] = useState('')
  const [certEmailSubject, setCertEmailSubject] = useState('')
  const [certFileName, setCertFileName] = useState('')
  const [smtpDetails, setSmtpDetails] = useState({
    from: '',
    host: '',
    port: '',
    username: '',
    password: ''
  })
  const [contactDetails, setContactDetails] = useState({
    poc: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    notes: ''
  })
  const [certMakerDetails, setCertMakerDetails] = useState({
    spreadsheetFields: '',
    pdfRenderPositions: '',
    font: '',
    color: ''
  })
  const [emailTemplates, setEmailTemplates] = useState({
    'day 2 lft': {
      negative: '',
      positive: '',
      inconclusive: ''
    },
    'fit to fly lft': {
      negative: '',
      positive: '',
      inconclusive: ''
    },
    'fit to fly pcr': {
      negative: '',
      positive: '',
      inconclusive: ''
    }
  })
  const clientRef = doc(firestore, 'clients', id)

  const [value, loading, error] = useDocument(
    clientRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (value) {
      const data = value.data()
      setName(data.name)
      setBarcodePrefix(data.barcodePrefix)
      setNextBarcode(data.nextBarcode)
      setFilePrefix(data.filePrefix)
      setCertEmailSubject(data.certEmailSubject || '')
      setCertFileName(data.certFileName || '')
      let { spreadsheetFields, pdfRenderPositions, font, color } = data
      setCertMakerDetails({ spreadsheetFields, pdfRenderPositions, font, color })
      data.contactDetails && setContactDetails(data.contactDetails)
      data.smtpDetails && setSmtpDetails(data.smtpDetails)
      data.emailTemplates && setEmailTemplates(data.emailTemplates)
    }
  }, [value])

  async function save() {
    let { spreadsheetFields, pdfRenderPositions, font, color } = certMakerDetails

    await setDoc(clientRef, {
      name,
      barcodePrefix,
      nextBarcode,
      filePrefix,
      smtpDetails,
      contactDetails,
      spreadsheetFields,
      pdfRenderPositions,
      font,
      color,
      emailTemplates,
      certEmailSubject,
      certFileName
    }, { merge: true })
  }

  return <Fragment>
    <WhenReady loading={loading} error={error} data={value}>
      <Box style={{ margin: '2em' }}>
        <AccordionGroup label={'Details'}>
          <TextField label="Name" value={name} onChange={e => setName(e.target.value)}/>
          <TextField label="Gov Upload Prefix" value={filePrefix} onChange={e => setFilePrefix(e.target.value)}/>
        </AccordionGroup>
        <ContactDetailsEdit contactDetails={contactDetails}
                            onChange={(contactDetails) => setContactDetails(contactDetails)}/>
        <SmtpDetailsEdit smtpDetails={smtpDetails} onChange={smtpDetails => setSmtpDetails(smtpDetails)}/>


        <BarcodeEdit barcodePrefix={barcodePrefix} nextBarcode={nextBarcode} setBarcodePrefix={setBarcodePrefix}
                     setNextBarcode={setNextBarcode} clientRef={clientRef}/>

        <CertMakerEdit providerId={id} certMakerDetails={certMakerDetails} setCertMakerDetails={setCertMakerDetails}/>
        <EmailTemplates providerId={id} hooks={{
          emailTemplates,
          setEmailTemplates,
          certFileName,
          certEmailSubject,
          setCertFileName,
          setCertEmailSubject
        }}/>
      </Box>
      <Pane style={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={() => save()}>Save</Button>
      </Pane>

    </WhenReady>
  </Fragment>
}

