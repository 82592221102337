/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Pane } from './ReactUtils'
import { Box, TextField } from '@mui/material'
import { useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Button from '@mui/material/Button'
import FileSaver from 'file-saver'

export function Settings() {
  const [labExportFields, setLabExportFields] = useState('')

  const settingsRef = doc(firestore, 'settings', 'global')

  const [value, loading, error] = useDocument(
    settingsRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (value) {
      const data = value.data()
      setLabExportFields(data.labExportFields)
    }
  }, [value])

  async function save() {
    await setDoc(settingsRef, {
      labExportFields
    }, { merge: true })
  }

  async function exportAll() {
    let samplesRef = collection(firestore, 'samples')
    const samples = await getDocs(samplesRef)

    const fields = labExportFields.split('\n').filter(a => a)
    const text = [fields.join(','), ...samples.docs.map(a => {
      let data = a.data()
      return fields.map(field => JSON.stringify(data[field]) || '').join(',')
    })].join('\n')

    const blob = new Blob([text], { type: 'text/csv' })

    FileSaver.saveAs(blob, 'samples-all.csv')
  }

  return <Fragment>
    <WhenReady loading={loading} error={error} data={value}>
      <Pane style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <TextField
          label="Lab Registered Export Fields"
          multiline={true}
          inputProps={{ style: { whiteSpace: 'nowrap' } }}
          rows={15}
          sx={{ width: '100%' }}
          value={labExportFields}
          onChange={(e) => setLabExportFields(e.target.value)}
        />
        <Box style={{ display: 'flex', gap: '1em', alignSelf: 'end' }}>
          <Button variant="contained" onClick={() => exportAll()}>Export All Samples</Button>
          <Button variant="contained" color="primary" onClick={() => save()}>Save</Button>
        </Box>
      </Pane>
    </WhenReady>
  </Fragment>
}

