import moment from 'moment'

const TYPE_TEST_TRANSFORMS = {
  'RT-PCR': {
    value: 'fit to fly pcr', label: 'Fit to Fly PCR'
  },
  'Rapid Antigen (LFT)': {
    value: 'fit to fly lft', label: 'Fit to Fly LFT'
  },
}

const date_parsing_trials = [
  'DD/MM/YYYY HH:mm:ss',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
]

function parseDateTime(date_string) {
  let date_object = null
  for (let i = 0; i < date_parsing_trials.length; i++) {
    try {
      date_object = moment(date_string, date_parsing_trials[i])
      if (date_object.isValid()) {
        break
      }
    } catch (e) {
      console.log('error parsing date', e)
    }
  }
  return date_object
}

export async function transformSample(entry) {
  if (!entry.SAMPLE_DATE) {
    if (entry.SAMPLE_DATE2) {
      entry.SAMPLE_DATE_VALUE = entry.SAMPLE_DATE2_VALUE
      delete entry.SAMPLE_DATE2
      delete entry.SAMPLE_DATE2_VALUE
    }
    if (!entry.SAMPLE_DATE_VALUE && entry.DATE_TEST && entry.TIME_TEST) {
      let m = parseDateTime(entry.DATE_TEST + ' ' + entry.TIME_TEST)
      entry.SAMPLE_DATE_VALUE = m.toISOString()
    }
  }

  if (entry.SAMPLE_DATE_VALUE) {
    let m = moment(entry.SAMPLE_DATE_VALUE)
    entry.DATE_TEST = m.format('DD/MM/YYYY')
    entry.TIME_TEST = m.format('HH:mm:ss')
    entry.SAMPLE_DATE = m.format('DD/MM/YYYY HH:mm')
  }

  entry.FULLNAME = [entry.FORENAMES, entry.SURNAME].filter(a => a).join(' ')
  entry.BARCODE_PREFIX = /^(\D*)\d*/gm.exec(entry.BARCODE)[1]
  !entry.RESULTS_VALUE && entry.RESULTS && (entry.RESULTS_VALUE = entry.RESULTS.trim().toLowerCase())
  !entry.TYPE_TEST_VALUE && entry.TYPE_TEST && (entry.TYPE_TEST_VALUE = entry.TYPE_TEST.trim().toLowerCase())
  !entry.SAMPLE_METHOD_VALUE && entry.SAMPLE_METHOD && (entry.SAMPLE_METHOD_VALUE = entry.SAMPLE_METHOD.trim().toLowerCase().replaceAll(' ', '-'))

  const testTypeTransform = TYPE_TEST_TRANSFORMS[entry.TYPE_TEST]
  if (testTypeTransform) {
    entry.TYPE_TEST_VALUE = testTypeTransform.value
    entry.TYPE_TEST = testTypeTransform.label
  }
}
