/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useState } from 'react'
import { DebouncedInput, Pane, SelectFirestoreCollection, SelectStatic, useQuery } from './ReactUtils'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection, deleteDoc, doc, limit, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import { auth, firestore } from './firebase'
import { WhenReady } from './AccessDenied'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import SendIcon from '@mui/icons-material/Send'
import Title from './Title'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material'
import { RESULT_TYPES, TYPE_TESTS, WORKFLOW } from './Workflows'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useNavigate } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

const Grid = React.memo(({ samples, columns }) => {
  return <DataGridPro disableColumnMenu
                      autoHeight={true}
                      rows={samples.docs.map((a, index) => ({
                        ...a.data(),
                        id: a.id,
                        name: `${a.data().FORENAMES} ${a.data().SURNAME}`,
                        index
                      }))}
                      getRowId={row => row.index}
                      columns={columns}
                      getRowClassName={(params) => `row-status--${params.row.status}`}
                      hideFooter={true}/>
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.samples.docs) === JSON.stringify(nextProps.samples.docs)
})

export function Samples() {
  const { query: urlQuery, result, status, testType, provider, barcode } = useQuery()
  const samplesCollection = collection(firestore, 'samples')
  let q = samplesCollection
  const [selectedRow, setSelectedRow] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const navigate = useNavigate()

  function handleDeleteDialog(params) {
    setSelectedRow(params)
    setOpenDeleteDialog(true)
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false)
    setSelectedRow(null)
  }

  async function handleDelete(id) {
    await deleteDoc(doc(samplesCollection, id))
  }

  function setFilter(key, value) {
    if (value) {
      urlQuery.set(key, value)
    } else {
      urlQuery.delete(key)
    }
    navigate(`?${urlQuery.toString()}`)
  }

  if (provider) {
    q = query(q, where('providerId', '==', provider))
  }
  if (testType) {
    q = query(q, where('TYPE_TEST_VALUE', '==', testType))
  }
  if (status) {
    q = query(q, where('status', '==', status))
  }
  if (result) {
    q = query(q, where('RESULTS_VALUE', '==', result))
  }
  if (barcode) {
    q = query(q, where('BARCODE', '>=', barcode.toUpperCase()),
      where('BARCODE', '<', barcode.toUpperCase() + '\uf8ff'))
  }
  q = query(q, limit(100))

  const [samples, loading, error] = useCollection(q)
  const [to, setTo] = useState('')

  function edit(row) {
    const { pathname, search } = global.location
    navigate(row.sampleId + '?back=' +pathname + search)
  }

  async function duplicate(row) {
    const { SAMPLE_METHOD, BARCODE, SAMPLE_DATE_VALUE, SAMPLE_DATE, TEST_DATE, TEST_TIME, PHOTO_URL, ...rest } = row
    const samplesColl = collection(firestore, 'samples')
    const id = doc(samplesColl).id
    await setDoc(doc(samplesColl, id), {
      ...rest,
      status: 'available',
      created: {
        by: auth.currentUser.email,
        timestamp: serverTimestamp()
      }
    })
    navigate(id)
  }

  const columns = [
    { field: 'providerId', headerName: 'Client', flex: .5 },
    { field: 'BARCODE', headerName: 'Barcode', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'EMAIL', headerName: 'Email', flex: 1 },
    { field: 'RESULTS', headerName: 'Results', flex: .5 },
    { field: 'TYPE_TEST', headerName: 'Test Type', flex: .5 },
    { field: 'status', headerName: 'Status', flex: .5 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (({ row }) => [
        <GridActionsCellItem showInMenu icon={<NoteAddIcon/>} label="Download PDF" onClick={() => {
          window.open(`/api/pdf/${row.sampleId}`, '_blank')
        }}/>,
        <GridActionsCellItem showInMenu icon={<CheckOutlinedIcon/>} label="Set as Completed" onClick={() => {
          updateDoc(doc(firestore, 'samples', row.sampleId), { status: 'completed' })
        }}/>,
        <GridActionsCellItem showInMenu icon={<SendIcon/>} label="Send E-Mail" onClick={() => sendEmail(row)}/>,
        <GridActionsCellItem showInMenu icon={<EditOutlinedIcon/>} label="Edit" onClick={() => edit(row)}/>,
        <GridActionsCellItem
          showInMenu
          icon={<Delete/>}
          label="Remove record"
          onClick={() => handleDeleteDialog(row)}
        />,

        <GridActionsCellItem showInMenu icon={<FileCopyOutlinedIcon/>} label="Duplicate"
                             onClick={() => duplicate(row)}/>,

      ])
    },
  ]

  async function sendEmail(row) {
    const { sampleId } = row
    console.log('sendEmail', sampleId)
    const res = await fetch(`/api/email/${sampleId}?to=${to}`)
    const ref = doc(firestore, 'samples', sampleId)
    if (res.status === 200) {
      await updateDoc(ref, { status: 'completed' })
    } else {
      await updateDoc(ref, { status: 'error', reason: await res.json() })
    }
    console.log('sendEmail', sampleId, 'done')
  }

  async function sendEmails() {
    samples && samples.docs && samples.docs.map(async (doc) => {
      const entry = doc.data()
      if (entry.status !== 'sent') {
        await sendEmail(entry)
        entry.status = 'sent'
        await setDoc(doc.ref, entry, { merge: true })
      }
    })
  }

  return <Fragment>
    <Pane>
      <Title>Samples</Title>
      <Box className="filterbox">
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Barcode</InputLabel>
          <DebouncedInput
            label="Barcode"
            sx={{ width: '10em' }}
            value={barcode}
            onChange={event => setFilter('barcode', event && event.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Provider</InputLabel>
          <SelectFirestoreCollection value={provider}
                                     dataPath={['clients']}
                                     dataTransform={client => ({ value: client.id, label: client.data().name })}
                                     onChange={event => setFilter('provider', event && event.value.toUpperCase())}
                                     minWidth={200}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Test Type</InputLabel>
          <SelectStatic value={testType}
                        options={Object.entries(TYPE_TESTS).map(([value, label]) => ({ value, label }))}
                        onChange={event => setFilter('testType', event && event.value)}
                        minWidth={200}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Status</InputLabel>
          <SelectStatic value={status}
                        options={Object.entries(WORKFLOW).map(([value, label]) => ({ value, label }))}
                        onChange={event => setFilter('status', event && event.value)}
                        minWidth={200}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Result</InputLabel>
          <SelectStatic clearable={true}
                        value={result}
                        options={Object.entries(RESULT_TYPES).map(([value, label]) => ({ value, label }))}
                        onChange={event => setFilter('result', event && event.value)}
                        minWidth={200}
          />
        </FormControl>
      </Box>
      <WhenReady loading={loading} error={error} data={samples}>
        <FormControl fullWidth sx={{ m: 1 }}>
          {samples && samples.docs && samples.docs.length && <Grid samples={samples} columns={columns}/> || <div></div>}
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>

          <TextField label="Override Destination Email"
                     onChange={e => setTo(e.target.value)}
                     value={to}
                     fullWidth
          />
        </FormControl>
        <Button variant={'contained'} onClick={sendEmails}>Send Emails</Button>
      </WhenReady>
    </Pane>
    {openDeleteDialog && <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
      <DialogTitle>Delete record?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
        <Button onClick={() => {
          handleCloseDeleteDialog()
          handleDelete(selectedRow.id)
        }} autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>}
  </Fragment>
}
