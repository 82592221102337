/* eslint-disable array-callback-return */
import * as React from 'react'
import { Fragment, useEffect } from 'react'
import { AccordionGroup, FormSelectStatic, Pane, useQuery } from './ReactUtils'
import { Box, Button, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { doc, setDoc } from 'firebase/firestore'
import { WhenReady } from './AccessDenied'
import Title from './Title'
import { SAMPLE_SCHEMA } from './Workflows'
import { DatePicker, DateTimePicker } from '@mui/lab'
import moment from 'moment'
import { transformSample } from 'lims-server/src/util/Transforms'

function Field({
                 data,
                 setData,
                 name,
                 label,
                 type,
                 width,
                 rows = 1,
                 options,
                 multiline = false,
                 separateValueField = true
               }) {
  const key = name
  const [value, setValue] = React.useState(data[key])
  if (type === 'select') {
    return <FormSelectStatic label={label}
                             value={data[separateValueField ? key + '_VALUE' : key] || ''}
                             onChange={value => {
                               if (separateValueField) {
                                 setData({
                                   [key]: options[value],
                                   [key + '_VALUE']: value
                                 })
                               } else {
                                 setData({ [key]: value })
                               }
                             }}
                             options={options}/>
  }
  if (type === 'image') {
    return <img src={data[key]} alt={label} style={{ maxWidth: '100%' }}/>
  }
  window.fabio = moment()
  if (type === 'datetime') {
    const v = data[separateValueField ? key + '_VALUE' : key]
    return <DateTimePicker
      label={label}
      inputFormat="DD/MM/YYYY HH:mm"
      renderInput={(props) => <TextField {...props}/>}
      sx={{ ...(width && { width: '20em' } || {}) }}
      value={v ? moment(v) : null}
      onChange={(newValue) => {
        let v = newValue.toISOString()
        setData({
          [key]: newValue.format('DD/MM/YYYY HH:mm'),
          [key + '_VALUE']: v
        })
      }}
    />
  }
  if (type === 'date') {
    const v = data[separateValueField ? key + '_VALUE' : key]
    return <DatePicker
      label={label}
      inputFormat="DD/MM/YYYY"
      renderInput={(props) => <TextField {...props}/>}
      sx={{ ...(width && { width: '20em' } || {}) }}
      value={v ? moment(v) : null}
      onChange={(newValue) => {
        let v = newValue.toISOString()
        setData({
          [key]: newValue.format('DD/MM/YYYY'),
          [key + '_VALUE']: v
        })
      }}
    />
  }
  return <TextField
    label={label}
    sx={{ ...(width && { width: '20em' } || {}) }}
    value={value || ''}
    onChange={(e) => {
      setValue(e.target.value)
    }}
    onBlur={() => {
      setData({ [key]: value })
    }}
    rows={rows}
    multiline={multiline}
  />
}

const DataGroup = React.memo(({ data, setData, group, fields, defaultExpanded = false }) => {
  return <AccordionGroup label={group}>
    {Object.entries(fields).map(([key, field]) => {
      return <Field key={key} data={data} setData={setData} name={key} {...field} />
    })}
  </AccordionGroup>
}, (prev, next) => {
  return Object.keys(prev.fields).every(key => prev.data[key] === next.data[key])
})

let lastData

export function SampleEdit() {
  const { sampleId } = useParams()
  const { back } = useQuery()
  const sampleRef = doc(firestore, 'samples', sampleId)
  const navigate = useNavigate()

  const [record, loading, error] = useDocument(
    sampleRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  const data = record && record.data() || {}

  async function save(goBack=false) {
    await transformSample(localRecord)
    await setDoc(sampleRef, localRecord, { merge: true })
    if (goBack) {
      navigate(back)
    }
  }

  const [localRecord, setLocalRecord] = React.useState({})
  lastData = localRecord

  useEffect(() => {
    if (record && data.status) {
      setLocalRecord({ ...data })
    }
  }, [record])

  function mergeData(data) {
    let newVar = { ...lastData, ...data }
    setLocalRecord(newVar)
  }

  return <Fragment>
    <WhenReady loading={loading} error={error}>
      {localRecord && localRecord.status && <Box style={{ margin: '2em' }}>
        <Title>{data.BARCODE} {data.FULLNAME}</Title>

        {Object.entries(SAMPLE_SCHEMA).map(([group, fields], index) => {
          return <DataGroup key={group} data={{ ...localRecord }} setData={mergeData} group={group} fields={fields}
                            defaultExpanded={index === 0}/>
        })}

      </Box>}

      <Pane style={{     justifyContent: 'flex-end', gap: '1em', display: 'flex' }}>
        <Button variant="outlined" color={back ? 'info' : 'primary'} onClick={() => save()}>Save</Button>
        {back && <Button variant="contained" color="primary" onClick={() => save(true)}>Save and Back</Button>}
      </Pane>

    </WhenReady>
  </Fragment>
}
