/* eslint-disable array-callback-return */
import * as React from 'react'
import { Pane, WhenNotEmpty } from './ReactUtils'
import Title from './Title'
import { Box, Button, FormControlLabel, FormGroup, Switch, Tab, Tabs, TextField } from '@mui/material'
import { collection, deleteDoc, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from './firebase'
import { WhenReady } from './AccessDenied'

export function ImportQueue() {
  const [value, setValue] = React.useState(0)
  const [hideImported, setHideImported] = React.useState(true)
  // let q = query(collection(firestore, 'importQueue'), orderBy('time', 'desc'))
  let q = query(collection(firestore, 'importQueue'), orderBy('status'), orderBy('time', 'desc'))
  if (hideImported) {
    q = query(q, where('status', '!=', 'imported'))
  }
  const [coll, loading, error] = useCollection(q)

  return <Pane>
    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
    <Title>Import Queue</Title>
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={hideImported} onChange={(e) => setHideImported(e.target.checked)}/>}
        label="Hide Imported"/>
    </FormGroup>
    </Box>
    <WhenReady loading={loading} error={error}>
      <WhenNotEmpty data={coll}>

        <Box style={{ display: 'flex', width: '100%', gap: '1em' }}>
          <Tabs
            value={value}
            orientation="vertical"
            onChange={(e, t) => setValue(t)}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {coll && coll.docs.map((item, index) => <Tab key={item.id}
                                                         label={item.data().time}/>)}
          </Tabs>
          {coll && coll.docs.map((item, index) => (

            <div
              role="tabpanel"
              hidden={value !== index}
              key={index}
              style={{ flexGrow: 1, gap: '1em' }}
            >
              {value === index && (
                <Box style={{
                  display: 'flex',
                  gap: '1em',
                  flexWrap: 'wrap',
                  alignContent: 'flex-start',
                  flexFlow: 'column'
                }}>

                  <Box style={{ display: 'flex', gap: '1em' }}>
                    <Button variant={'contained'} onClick={() => {
                      updateDoc(item.ref, { status: 'ready-to-import' })
                    }}>Re-import</Button>
                    <Button variant={'contained'} onClick={() => {
                      deleteDoc(item.ref)
                    }}>Delete</Button>

                  </Box>
                  <TextField
                    label="Job"
                    multiline={true}
                    rows={30}
                    inputProps={{ style: { whiteSpace: 'nowrap' } }}
                    value={JSON.stringify(item.data(), null, ' ')}
                  />
                </Box>
              )}
            </div>

          ))}
        </Box>
      </WhenNotEmpty>
    </WhenReady>

  </Pane>
}
