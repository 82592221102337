import * as React from 'react'
import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { ButtonWithQuestion, Pane, SimpleDataGridEditor } from './ReactUtils'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { jsPDF } from 'jspdf'
import JsBarcode from 'jsbarcode'
import 'svg2pdf.js'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from './firebase'
import { useNavigate } from 'react-router-dom'

// export const Clients = withTheme(_Clients)

export function Clients() {
  const [generateBarcodeOpen, setGenerateBarcodeOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const navigate = useNavigate()

  function openGenerateBarcodes(params) {
    setSelectedRow(params)
    setGenerateBarcodeOpen(true)
  }

  async function generateBarcodes({ count, repeats }) {
    const { nextBarcode, barcodePrefix } = selectedRow.row

    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [1.06, 0.91]
    })

    const width = pdf.internal.pageSize.getWidth()
    const height = pdf.internal.pageSize.getHeight()

    const svg = document.createElement('svg')

    for (let offset = 0; offset < count; offset++) {
      const barcodeNumber = nextBarcode + offset
      const s = barcodePrefix + barcodeNumber.toString().padStart(7, '0')
      JsBarcode(svg, s, { format: 'CODE128' })

      for (let i = 0; i < repeats; i++) {
        (offset || i) && pdf.addPage()
        await pdf.svg(svg, { x: 0, y: 0, width, height })
      }
    }

    pdf.save('barcodes.pdf')

    await setDoc(doc(firestore, 'clients', selectedRow.id), { nextBarcode: nextBarcode + count }, { merge: true })
  }

  function closeGenerateBarcodes() {
    setGenerateBarcodeOpen(false)
  }

  const columns = [
    { field: 'id', headerName: 'Client Id', editable: false },
    { field: 'name', headerName: 'Name', width: 300, editable: true },
    { field: 'filePrefix', headerName: 'Gov Upload Prefix', width: 150, editable: true },
    { field: 'barcodePrefix', headerName: 'Barcode Prefix', width: 130, editable: true },
    { field: 'nextBarcode', headerName: 'Next Barcode', width: 120, type: 'number', editable: true },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          showInMenu
          icon={<QrCode2Icon/>}
          label="Generate Barcodes"
          onClick={() => openGenerateBarcodes(params)}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<RefreshIcon/>}
          label="Clear Certificates Cache"
          onClick={() => fetch('/api/clear-certificates-cache?providerId=' + params.id)}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<ModeEditOutlineOutlinedIcon/>}
          label="Edit"
          onClick={() => navigate('/client/' + params.id)}
        />,
      ],
    },
  ]

  return <Pane>
    <SimpleDataGridEditor
      title="Clients"
      collectionPath="clients"
      mainField="id"
      columns={columns}
      addButtonBuilder={handleAdd => createAddButtonBuilder(handleAdd)}/>
    <BarcodeQuestion open={generateBarcodeOpen} onConfirm={generateBarcodes} handleClose={closeGenerateBarcodes}/>
  </Pane>
}

function createAddButtonBuilder(handleAdd) {
  return <ButtonWithQuestion
    title="New Client"
    tooltip="New Client"
    content={
      <span>Please enter the client id. This id should be a short handle to identify this client through the system.</span>}
    label="Client Id"
    type="text"
    onConfirm={id => handleAdd(id)}>
    <AddIcon/> Add record
  </ButtonWithQuestion>
}

export function BarcodeQuestion({ open, onConfirm, handleClose }) {
  const [count, setCount] = useState(1)
  const [repeats, setRepeats] = useState(1)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Generate Barcodes</DialogTitle>
      <DialogContent>
        <DialogContentText>Generate barcodes to assign to test kits.</DialogContentText>
        <TextField autoFocus margin="dense" label="How many different barcodes?" type="number" fullWidth
                   variant="standard" value={count}
                   onChange={e => setCount(parseInt(e.target.value))}/>
        <TextField autoFocus margin="dense" label="Repeats" type="number" fullWidth variant="standard" value={repeats}
                   onChange={e => setRepeats(parseInt(e.target.value))}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => {
          handleClose()
          onConfirm({ count, repeats })
        }}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}
