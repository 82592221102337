import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { ClientsSimple } from './ClientsSimple'
import { Client } from './Client'
import { TestTypes } from './TestTypes'
import { GenerateCSV } from './GenerateCSV'
import { Samples } from './Samples'
import { ImportQueue } from './ImportQueue'
import { Users } from './Users'
import { SampleEdit } from './SampleEdit'
import { Settings } from './Settings'

export function Admin() { // eslint-disable-line react/display-name
  const location = useLocation()
  const [, , tab = 'clients', edit] = location.pathname.split('/')

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <Tabs value={tab} className="TopMenu" variant="scrollable" scrollButtons="auto">
          <Tab label="Clients" value="clients" to="clients" component={Link}/>
          {tab === 'client' && edit && <Tab label={edit} value="client" to={`client/${edit}`} component={Link}/>}
          <Tab label="Generate Gov CSV" value="generateCsv" to="generateCsv" component={Link}/>
          <Tab label="Master List" value="samples" to="samples" component={Link}/>
          {tab === 'samples' && edit && <Tab label={edit} value="samples" to={`samples/${edit}`} component={Link}/>}
          <Tab label="Test Types" value="testTypes" to="testTypes" component={Link}/>
          <Tab label="Import Queue" value="importQueue" to="importQueue" component={Link}/>
          <Tab label="Users" value="users" to="users" component={Link}/>
          <Tab label="Settings" value="settings" to="settings" component={Link}/>
        </Tabs>
      </Box>
      <Routes>
        <Route path="/" element={<ClientsSimple/>}/>
        <Route path="clients" element={<ClientsSimple/>}/>
        <Route path="client/:id" element={<Client/>}/>
        <Route path="testTypes" element={<TestTypes/>}/>
        <Route path="generateCsv" element={<GenerateCSV/>}/>
        <Route path="samples" element={<Samples/>}/>
        <Route path="samples/:sampleId" element={<SampleEdit/>}/>
        <Route path="importQueue" element={<ImportQueue/>}/>
        <Route path="testTypes" element={<TestTypes/>}/>
        <Route path="users" element={<Users/>}/>
        <Route path="settings" element={<Settings/>}/>
      </Routes>
    </Fragment>
  )
}
