import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { ButtonWithQuestion, Pane } from './ReactUtils'
import 'svg2pdf.js'
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { auth, firestore } from './firebase'
import { useNavigate } from 'react-router-dom'
import { useCollection } from 'react-firebase-hooks/firestore'
import { WhenReady } from './AccessDenied'
import { Typography } from '@mui/material'
import Title from './Title'
import Button from '@mui/material/Button'

export function ClientsSimple() {
  const clientsRef = collection(firestore, 'clients')
  const [data, loading, error] = useCollection(clientsRef)

  const navigate = useNavigate()

  return <Pane>
    <WhenReady data={data} error={error} loading={loading}>
      <Title>Clients</Title>
      <ButtonWithQuestion
        content={
          <span>Please enter the client id. This id should be a short handle to identify this client through the system.</span>}
        label="Client Id"
        type="text"
        onConfirm={id => setDoc(doc(clientsRef, id), {
          created: {
            by: auth.currentUser.email,
            timestamp: serverTimestamp()
          }
        })}>
        <AddIcon/> Add record
      </ButtonWithQuestion>
      {data && data.docs.map(client => {
        const id = client.id

        function onClick(e) {
          e.preventDefault()
          navigate(`/admin/client/${id}`)
        }

        return <Typography sx={{ p: 1 }} key={id}>
          <Button onClick={onClick}>{client.data().name || client.id}</Button>
        </Typography>
      })}
    </WhenReady>
  </Pane>
}
