import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { LabRegistered } from './LabRegistered'

export function Lab() { // eslint-disable-line react/display-name
  const location = useLocation()
  const tab = location.pathname.split('/')[2] || 'registered'

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <Tabs value={tab} className="TopMenu" variant="scrollable" scrollButtons="auto">
          <Tab label="Registered" value="registered" to="registered" component={Link}/>
        </Tabs>
      </Box>
      <Routes>
        <Route path={'/'} element={<LabRegistered/>}/>
        <Route path={'registered'} element={<LabRegistered/>}/>
      </Routes>
    </Fragment>
  )
}
